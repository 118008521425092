<template lang="pug">
  #app
    .container
      Book
</template>

<script>
import Book from './components/Book.vue';

export default {
  name: 'App',
  components: {
    Book,
  },
};
</script>

<style lang="scss">
#app {
  background-color: $color-athens-gray;
}
</style>
