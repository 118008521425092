<template lang="pug">
.progress-circle
  svg.progress-circle__chart(viewBox='-3 -3 42 42')
    path.progress-circle__chart__bg(
      d='M18 2.0845\
      a 15.9155 15.9155 0 0 1 0 31.831\
      a 15.9155 15.9155 0 0 1 0 -31.831'
    )
    path.progress-circle__chart__circle(
      :stroke-dasharray='`${progressPercentage}, 100`'
      d='M18 2.0845\
      a 15.9155 15.9155 0 0 1 0 31.831\
      a 15.9155 15.9155 0 0 1 0 -31.831'
    )
</template>

<script>
export default {
  name: 'ProgressCircle',
  props: {
    progressPercentage: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss">
.progress-circle {
  width: 1em;
  height: 1em;
  position: absolute;
  left: 0;
  @include mq($until: md) {
    width: 1.5em;
    height: 1.5em;
  }
  &__chart {
    &__bg,
    &__circle {
      fill: none;
    }
    &__bg {
      stroke-width: 7;
      stroke: $color-teal-blue;
    }
    &__circle {
      stroke-width: 7;
      stroke: $color-bondi-blue;
    }
  }
}
</style>
